<template>
  <q-card class="full-width" square>
    <div class="row">
      <div v-if="$q.screen.gt.sm" class="col-4 bg-primary q-pa-md" />
      <div class="col-12 col-md-8 q-pa-md">
        <div class="q-pa-lg q-pa-sm-xl">
          <div class="text-h4 text-weight-bold text-grey-8">
            {{ $appName }}
          </div>
          <q-form @submit="login" class="q-gutter-md q-mt-lg">
            <q-input
              ref="username"
              filled
              v-model.trim="username"
              label="Username*"
              lazy-rules
              :rules="rules"
              autocomplete="username"
            />
            <q-input
              ref="password"
              filled
              v-model.trim="password"
              label="Password*"
              lazy-rules
              :rules="rules"
              type="password"
              autocomplete="current-password"
            />
            <q-input
              v-if="isShowOTP"
              filled
              v-model.trim="otp"
              label="OTP code*"
              lazy-rules
              :rules="rules"
              type="password"
            />
            <div>
              <q-btn
                v-if="isShowResendBtn"
                label="Resend OTP"
                outline
                class="full-width q-mb-sm"
                color="primary"
                :disable="isDisabledResendBtn"
                @click="resendOtp"
              />
              <q-btn
                label="Login"
                class="full-width"
                type="submit"
                color="primary"
              />
            </div>
          </q-form>
        </div>
      </div>
    </div>
  </q-card>
</template>

<script>
import { MESSAGES_401 } from '@/shared/constants';

export default {
  data: () => ({
    isShowOTP: false,
    isShowResendBtn: false,
    isDisabledResendBtn: false,
    resendBtnTimer: null,
    username: null,
    password: null,
    otp: null,
    rules: [(val) => (val && val.length > 0) || 'The field must not be empty'],
  }),
  methods: {
    async login() {
      try {
        await this.$store.dispatch('LOG_IN', {
          username: this.username,
          password: this.password,
          otp: this.otp || undefined,
        });
        await this.$router.push('/');
      } catch (e) {
        let notifyMessage = '';
        if (e.message === MESSAGES_401.MISSING_OTP) {
          this.isShowOTP = true;
          this.isShowResendBtn = true;
          return;
        } else if (e.message === MESSAGES_401.TIMEOUT) {
          this.resetResendBtn();
          return;
        } else if (e.message === MESSAGES_401.INVALID_OTP) {
          notifyMessage = 'Invalid OTP';
        } else {
          notifyMessage = 'Invalid credentials';
        }

        this.$q.notify({
          type: 'negative',
          message: notifyMessage,
          position: 'top-right',
        });
      }
    },
    async resendOtp() {
      let results = [
        this.$refs.username.validate(),
        this.$refs.password.validate(),
      ];

      if (results.includes(false)) return;

      try {
        await this.$store.dispatch('LOG_IN', {
          username: this.username,
          password: this.password,
        });
      } catch (e) {
        this.resetResendBtn();
      }
    },
    resetResendBtn() {
      this.isShowOTP = true;
      this.isShowResendBtn = true;
      this.isDisabledResendBtn = true;
      this.resendBtnTimer = setTimeout(() => {
        this.isDisabledResendBtn = false;
        clearTimeout(this.resendBtnTimer);
      }, 2 * 60 * 1000);
    },
  },
};
</script>
