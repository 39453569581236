<template>
  <q-page class="page">
    <div class="wrapper">
      <d-auth-card />
    </div>
  </q-page>
</template>

<script>
import { DAuthCard } from '@/features/auth-card';

export default {
  components: { DAuthCard },
};
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex: 1 1 auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 600px;

  padding: 12px;
  margin: 0 auto;
}
</style>
